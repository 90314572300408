import { Icon } from '@chakra-ui/react';
import { MdOutlinePoll, MdPerson, MdOutlineGridView, MdOutlineLogout, MdOutlineDiamond, MdOutlineSettings, MdOutlinePublish, MdGrading  } from 'react-icons/md';

// Admin Imports
import MainDashboard from 'views/admin/default';
import Settings from 'views/admin/settings';
import Positions from 'views/admin/positions';

import Users from 'views/admin/users';
import Logs from 'views/admin/logs';
import ApiDetails from 'views/admin/apiDetails';
import ManualPush from 'views/admin/manualPush';

// Auth Imports
import SignInCentered from 'views/auth/signIn';
let routes = [
	{
		name: 'Dashboard',
		path: '/dashboard',
		icon: <Icon as={MdOutlineGridView} width='25px' height='25px' color='inherit' />,
		component: MainDashboard
	},
	{
		name: 'Settings',
		path: '/settings',
		icon: <Icon as={MdOutlineSettings} width='25px' height='25px' color='inherit' />,
		component: Settings,
		secondary: true
	},
	{
		name: 'API Details',
		icon: <Icon as={MdOutlineDiamond} width='25px' height='25px' color='inherit' />,
		path: '/api-details',
		component: ApiDetails
	},
	{
		name: 'Positions',
		path: '/positions',
		icon: <Icon as={MdOutlinePoll} width='25px' height='25px' color='inherit' />,
		component: Positions
	}
];
const admin_routes = [{
	name: 'Users',
	path: '/users',
	icon: <Icon as={MdPerson} width='25px' height='25px' color='inherit' />,
	component: Users
},
{
	name: 'Logs',
	path: '/logs',
	icon: <Icon as={MdGrading} width='25px' height='25px' color='inherit' />,
	component: Logs
},
{
	name: 'Manual Push',
	path: '/manual-push',
	icon: <Icon as={MdOutlinePublish} width='25px' height='25px' color='inherit' />,
	component: ManualPush
}];
const logout_route = [{
	name: 'Log out',
	path: '/sign-in',
	icon: <Icon as={MdOutlineLogout} width='25px' height='25px' color='inherit' />,
	component: SignInCentered
}];
if (!(localStorage.getItem('user') === 'null' || localStorage.getItem('user') === null)) {
	const userInfo = JSON.parse(localStorage.getItem('user'));
	console.log(userInfo);
	if (userInfo.username === "info@tradingmachine.ai") {
		routes = [...routes, ...admin_routes]
	} 
}
routes = [...routes, ...logout_route]

export default routes;

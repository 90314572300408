import { useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  SimpleGrid,
  Flex,
  Text,
  Select,
  Alert,
  AlertIcon
} from '@chakra-ui/react';

import Card from 'components/card/Card';
import { HSeparator } from 'components/separator/Separator';
import axios from "axios";



const PasswordChangeForm = () => {
  const [preLoading, setPreLoading] = useState(false);

  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [selectedLeverage, setSelectedLeverage] = useState(JSON.parse(localStorage.getItem('user')).leverage);
  const serverApi = process.env.REACT_APP_API;
  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const user_id = localStorage.getItem("user_id");
    if (newPassword === confirmPassword) {
      try {
        const response: any = await axios.post(`${serverApi}change_password`, {
          "user_id": user_id,
          "currentPassword": currentPassword,
          "newPassword": newPassword,
        });
        alert(response.data.result)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  };
  const handleLeverageSubmit = async (event: any) => {
    event.preventDefault();
    const user_id = localStorage.getItem("user_id");
    try {
      const response: any = await axios.post(`${serverApi}setleverage`, {
        "user_id": user_id,
        "leverage": selectedLeverage,
      });
      if (response.data.result) {
        let user_info = JSON.parse(localStorage.getItem('user'))
        user_info.leverage = selectedLeverage;
        localStorage.setItem('user', JSON.stringify(user_info));
      }
      setPreLoading(true);
      setTimeout(() => setPreLoading(false), 2000);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  const handleLeverage = (event :any) => {
    console.log(event.target.value);
    setSelectedLeverage(event.target.value);
    
  }

  return (
    <Box pt={{ base: '130px', md: '80px', xl: '80px' }}>
      {
        preLoading === true ? <Alert status='success' position='fixed' top='20px' width='280px' right='20px' zIndex='99'>
          <AlertIcon />
          Leverage successfully Changed.
        </Alert> : ""
      }
      <SimpleGrid columns={{ base: 1, md: 1, lg: 1, '2xl': 1 }} gap='20px' mb='20px'>
        <Card py='20px' px='30px' >
          <Flex px='10px' mb="20px" mt='20px' justifyContent='space-between' align='center'>
            <Text fontSize='22px' fontWeight='700' lineHeight='100%'>
              Change Password
            </Text>
          </Flex>
          <HSeparator mb='20px' />
          <Box>
            <form onSubmit={handleSubmit}>
              <Stack spacing={6}>
                <FormControl isRequired>
                  <FormLabel>Current password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Enter current password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>New password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Enter password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel>Confirm new password</FormLabel>
                  <Input
                    type="password"
                    placeholder="Re-enter password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                </FormControl>
                <Button
                  type="submit"
                  boxShadow="sm"
                  colorScheme='messenger'
                  _hover={{ boxShadow: 'md' }}
                  _active={{ boxShadow: 'lg' }}
                >
                  Save
                </Button>
              </Stack>
            </form>
          </Box>
        </Card>
        {localStorage.getItem('role') === 'user' && (
        <Card py='20px' px='30px' >
          <Flex px='10px' mb="20px" mt='20px' justifyContent='space-between' align='center'>
            <Text fontSize='22px' fontWeight='700' lineHeight='100%'>
              Leverage Setting
            </Text>
          </Flex>
          <HSeparator mb='20px' />
          <Box>
              <Stack spacing={6}>
                <FormControl>
                  <FormLabel>Leverage</FormLabel>
                  <Select value={selectedLeverage} onChange={handleLeverage} marginLeft='3'>
                    {Array.from({ length: 25 }, (_, index) => (
                      <option key={index + 1} value={index + 1}>
                        {index + 1}x
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <Button
                  onClick={handleLeverageSubmit}
                  boxShadow="sm"
                  colorScheme='messenger'
                  _hover={{ boxShadow: 'md' }}
                  _active={{ boxShadow: 'lg' }}
                >
                  Save
                </Button>
              </Stack>
          </Box>
        </Card>
        )}
      </SimpleGrid>
    </Box>
  );
};

export default PasswordChangeForm;
import { Flex, Box, Table, Stack, Skeleton, Tbody, Td, Text, Thead, Tr, useColorModeValue, Button, useToken} from '@chakra-ui/react';
import Card from 'components/card/Card';
import { Link } from 'react-router-dom';

// const columns = columnsDataCheck;
export default function ActivePositions(props: { tableData: any }) {
	const { tableData } = props;
	const textColor = useColorModeValue('secondaryGray.900', 'white');
	const [oddRowColor] = useToken('colors', ['gray.200']);
	return (
		<Card flexDirection='column' w='100%' px='0px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
			<Flex px='25px' mb="8px" justifyContent='space-between' align='center'>
				<Text color={textColor} fontSize='22px' mb="4px" fontWeight='700' lineHeight='100%'>
					Active Positions
				</Text>
			</Flex>
			<Box>
				{tableData == null ? <Stack width='100%' px='20px' my='30px'>
					<Skeleton height='25px' width='100%' />
					<Skeleton height='25px' width='100%' />
					<Skeleton height='25px' width='100%' />
					<Skeleton height='25px' width='100%' />
					<Skeleton height='25px' width='100%' />
					<Skeleton height='25px' width='100%' />
					<Skeleton height='25px' width='100%' />
					<Skeleton height='25px' width='100%' />
					<Skeleton height='25px' width='100%' />
				</Stack> : <Table variant='simple' color='gray.900' mb='24px' mt="12px">

					<Thead>
						<Tr >
							<Td padding='3' color='black' fontWeight='800' fontSize='18px'>Symbol</Td>
							<Td padding='3' color='black' fontWeight='800' fontSize='18px'>Side</Td>
							<Td padding='3' color='black' fontWeight='800' fontSize='18px'>Leverage</Td>
							<Td padding='3' color='black' fontWeight='800' fontSize='18px'>Qty</Td>
							<Td padding='3' color='black' fontWeight='800' fontSize='18px'>Order Value</Td>
							<Td padding='3' color='black' fontWeight='800' fontSize='18px'>Avg Entry Price</Td>
							<Td padding='3' color='black' fontWeight='800' fontSize='18px'>Mark Price</Td>
							<Td padding='3' color='black' fontWeight='800' fontSize='18px'>Margin</Td>
							<Td padding='3' color='black' fontWeight='800' fontSize='18px'>Unrealized P&L</Td>
						</Tr>
					</Thead>
					<Tbody>

						{
							tableData && tableData.map((data: any, index: any) => {
								return <Tr key='index' bg={index % 2 === 0 ? oddRowColor : ''}>
									<Td fontSize='lg' padding='3'>{data.symbol}</Td>
									<Td fontSize='lg' padding='3'>{data.side === "long" ? <Button colorScheme='green' borderRadius='10px' size='xs'>Long</Button> : <Button colorScheme='red' borderRadius='10px' size='xs'>Short</Button>}</Td>
									<Td fontSize='lg' padding='3'>cross {data.info.info.leverage}x</Td>
									<Td fontSize='lg' padding='3'>{Number(data.info.contracts).toFixed(4)}</Td>
									<Td fontSize='lg' padding='3'>{Number(data.info.collateral).toFixed(2)}</Td>
									<Td fontSize='lg' padding='3'>{Number(data.info.entryPrice).toFixed(2)}</Td>
									<Td fontSize='lg' padding='3'>{Number(data.info.info.markPrice).toFixed(2)}</Td>
									<Td fontSize='lg' padding='3'>{Number(data.info.initialMargin).toFixed(4)}</Td>
									{
										data.info.unrealizedPnl > 0 ? <Td padding='3' textAlign='center' color='green'>{Number(data.info.unrealizedPnl).toFixed(4)}<br />
											({(Number(data.info.unrealizedPnl) / Number(data.info.collateral) * 100).toFixed(4)}%)</Td> : <Td padding='3' textAlign='center' color='red'>{Number(data.info.unrealizedPnl).toFixed(4)}<br />
											({(Number(data.info.unrealizedPnl) / Number(data.info.collateral) * 100).toFixed(4)}%)</Td>
									}

								</Tr>
							})
						}

					</Tbody>

				</Table>}

			</Box>
			<Link to='/positions'><Button colorScheme='linkedin' w='180px' mx='20px' borderRadius='10px'>View All Positions</Button></Link>
		</Card>
	);
} 
import axios from "axios";

export async function login({email, password} :any) {
  const response: any = await axios.post('https://app.tradingmachine.ai/api/signin',{
    email: email,
    password: password
  })

  if (response.status === 200) {
    const user = response.data.user;
    setSession({ user });
    return 'success';
  } else {
    setSession({ user: null });
    return 'failed';
  }
} 

export function logout() {
  setSession({ user: null });
}

const setSession = ({ user }: any) => {
  if (user) {
    if (user.username === "info@tradingmachine.ai") {
      localStorage.setItem('role', 'admin');
    } else {
      localStorage.setItem('role', 'user');
    }
    localStorage.setItem('user_id', user.id);
    localStorage.setItem('user', JSON.stringify(user));
  } else {
    localStorage.removeItem('user');
  }
};
import { Flex, Box, Table, Tbody, Td, Text, Th, Thead, Tr, Input, Button, CircularProgress, FormControl, Select, useToken } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { HSeparator } from 'components/separator/Separator';
import axios from 'axios';
import { SortingState } from '@tanstack/react-table';
import Card from 'components/card/Card';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';
export default function UserTable() {
    const [mainData, setMainData] = React.useState<SortingState>([]);
    const [total_users, setTotalUsers] = useState();
    const [loading, setLoading] = useState(false)
    const [current_page, setCurrnetPage] = useState(1);
    const [email, setEmail] = useState('');
    const [state, setState ] = useState({
        selectedOption: 10,
        options: [
            { value: 10, label: "10" },
            { value: 20, label: "20" },
            { value: 30, label: "30" }
        ]
    });
    const [oddRowColor] = useToken('colors', ['gray.200']);
    const serverApi = process.env.REACT_APP_API;
    const fetchData = async () => {
        setLoading(true);
        try {
            const tableResponse = await axios.get(`${serverApi}getusers/?page=1&limit=${state.selectedOption}&email=${email}`);
            setMainData(tableResponse.data.result);
            setTotalUsers(tableResponse.data.result[0].total_users);
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);
    const handleChange = async (event: any) => {
        await setState({ ...state, selectedOption: event.target.value });
        const tableResponse = await axios.get(`${serverApi}getusers/?page=${current_page}&limit=${event.target.value}&email=${email}`);
        setMainData(tableResponse.data.result);
        setTotalUsers(tableResponse.data.result[0].total_users);
        setCurrnetPage(current_page);
    };
    const handlePause = async (user_id: any) => {
        setLoading(true);
        try {
            await axios.put(`${serverApi}update_user_status/`, {
                "user_id": user_id,
                "status": 2
            });
            fetchData();
            setLoading(false);
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const nextPage = async () => {
        const tableResponse = await axios.get(`${serverApi}getusers/?page=${current_page + 1}&limit=${state.selectedOption}&email=${email}`);
        setMainData(tableResponse.data.result);
        setTotalUsers(tableResponse.data.result[0].total_users);
        setCurrnetPage(current_page + 1);
    }
    const beforePage = async () => {
        const tableResponse = await axios.get(`${serverApi}getusers/?page=${current_page - 1}&limit=${state.selectedOption}&email=${email}`);
        setMainData(tableResponse.data.result);
        setTotalUsers(tableResponse.data.result[0].total_users);
        if (current_page > 1) {
            setCurrnetPage(current_page - 1);
        }

    }
    const handleActive = async (user_id: any) => {
        try {
            await axios.put(`${serverApi}update_user_status/`, {
                "user_id": user_id,
                "status": 1
            });
            fetchData();
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleVerified = async (user_id: any) => {
        try {
            await axios.put(`${serverApi}update_email_verified/`, {
                "user_id": user_id,
                "status": 1
            });
            fetchData();
        } catch (error) {
            console.error('Error:', error);
        }
    }
    const handleDisabled = async (user_id: any) => {
        try {
            await axios.put(`${serverApi}update_email_verified/`, {
                "user_id": user_id,
                "status": 0
            });
            fetchData();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    const handleDelete = async (user_id: any) => {
        await axios.delete(`${serverApi}delete_user/`, {
            data: {
                user_id: user_id
            }
        });
        fetchData();
    }
    const Search = async (data: any) => {

        const tableResponse = await axios.get(`${serverApi}getusers/?page=1&limit=10&email=${data}`);
        setMainData(tableResponse.data.result);
        setTotalUsers(tableResponse.data.result[0].total_users);
        setEmail(data);
    }
    const handleDashboard =async (id:number) => {
        const url = `/dashboard?user_id=${id}`;
        window.open(url, '_blank');
    }
    const handlePositions =async (id:number) => {
        const url = `/positions?user_id=${id}`;
        window.open(url, '_blank');
    }
    const DateTimeConverter = (originalDateTimeString: string) => {
        if (originalDateTimeString === null || originalDateTimeString === '') {
            return;
        }
        const dateTime = new Date(originalDateTimeString);

        // const formattedTime = dateTime.toLocaleTimeString([], { hour12: false });
        const formattedDate = dateTime.toLocaleDateString();
        return `${formattedDate}`;

    }
    return (
        <Box>
            <Box>
                <form>
                    <Flex flexDirection='row' gap='3' w={{ sm: '100%', lg: '80%' }}>
                        <FormControl>
                            <Input
                                onChange={(e) => Search(e.target.value)}
                                type="text"
                                w={{ sm: '100%', lg: '300px' }}
                                placeholder="Search by Email or User name"
                            />
                        </FormControl>
                    </Flex>
                </form>
            </Box>
            <Card flexDirection='column' w='100%' mt='10px' overflowX={{ sm: 'scroll', lg: 'hidden' }}>
                <Box>
                    <Text fontSize='20px' mb='20px'>Users</Text>
                    <HSeparator mb='20px' />
                    {
                        loading === true ? <CircularProgress isIndeterminate color='lightblue' /> : <>
                            <Table variant='simple' color='black' mb='24px' mt="12px">
                                <Thead>
                                    <Tr height='60px'>
                                        <Th fontSize='16px' textAlign='left' padding='1' fontWeight='500'>
                                            ID
                                        </Th>
                                        <Th fontSize='16px' textAlign='left' padding='1' fontWeight='500'>
                                            Email
                                        </Th>
                                        <Th fontSize='16px' textAlign='left' padding='1' fontWeight='500'>
                                            Name
                                        </Th>
                                        <Th fontSize='16px' textAlign='left' padding='1' fontWeight='500'>
                                            API
                                        </Th>
                                        <Th fontSize='16px' textAlign='left' padding='1' fontWeight='500'>
                                            Free
                                        </Th>
                                        <Th fontSize='16px' textAlign='left' padding='1' fontWeight='500'>
                                            Used
                                        </Th>
                                        <Th fontSize='16px' textAlign='left' padding='1' fontWeight='500'>
                                            Total USDt
                                        </Th>
                                        <Th fontSize='16px' textAlign='left' padding='1' fontWeight='500'>
                                            Created At
                                        </Th>
                                        <Th w='100px' fontSize='16px' textAlign='left' padding='1' fontWeight='500'>
                                            Manage
                                        </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {
                                        mainData && mainData.map((data: any, index: any) => {
                                            return (
                                                <Tr key={index} bg={index % 2 === 0 ? oddRowColor : ''}>
                                                    <Td fontSize='lg' padding='1'>
                                                        {data.id}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1'>
                                                        <Text >{data.email}</Text>
                                                        {
                                                            data.email_verified === 1 ? <Button colorScheme='green' borderRadius='5px' fontSize='10px' size='xs' marginTop="10px">Email Verified</Button> : <Button colorScheme='red' borderRadius='5px' fontSize='10px' size='xs'>Email Not Verified</Button>
                                                        }
                                                    </Td>
                                                    <Td fontSize='lg' padding='1'>
                                                        {data.name}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1'>
                                                        {data.exchange}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1'>
                                                        {Number(data.balance).toFixed(2)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1'>
                                                        {(Number(data.balance) + Number(data.balance_used)).toFixed(2)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1'>
                                                        {Number(data.balance_used).toFixed(2)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1'>
                                                        {DateTimeConverter(data.created_at)}
                                                    </Td>
                                                    <Td fontSize='lg' padding='1'>
                                                        <Flex flexDirection='column' gap='1'>
                                                            {
                                                                data.status === 1 ? <Button colorScheme='orange' borderRadius='8px' size='sx' p='5px' onClick={(e) => handlePause(data.id)}>Pause</Button> : <Button colorScheme='green' borderRadius='8px' size='sx' p='5px' onClick={(e) => handleActive(data.id)}>Active</Button>
                                                            }
                                                            {
                                                                data.email_verified === 0 ? <Button colorScheme='linkedin' borderRadius='8px' size='sx' p='5px' onClick={(e) => handleVerified(data.id)}>Verify Email</Button> : <Button colorScheme='linkedin' borderRadius='8px' size='sx' p='5px' onClick={(e) => handleDisabled(data.id)}>Disable Email</Button>
                                                            }
                                                            <Button colorScheme='messenger' borderRadius='8px' size='sx' p='5px' onClick={(e) => handleDashboard(data.id)}>Stats Dashboard</Button>
                                                            <Button colorScheme='yellow' borderRadius='8px' size='sx' p='5px' onClick={(e) => handlePositions(data.id)}>Positions</Button>
                                                            <Button colorScheme='red' borderRadius='8px' size='sx' p='5px' onClick={(e) => handleDelete(data.id)}>Delete</Button>
                                                        </Flex>
                                                    </Td>
                                                </Tr>
                                            )
                                        })
                                    }
                                </Tbody>
                            </Table>
                            <Flex justifyContent='space-between'>
                                <Flex justifyContent='space-between' alignItems='center'>
                                    <Text whiteSpace="nowrap">{Number(state.selectedOption) * (current_page - 1) + 1} - {Number(state.selectedOption) * current_page} of {total_users} Items per page</Text>
                                    <Select value={state.selectedOption} onChange={handleChange} marginLeft='3'>
                                        {state.options.map((option: any) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </Select>
                                </Flex>
                                <Flex justifyContent='center' textAlign='left' padding='1' alignItems='center'>
                                    {
                                        current_page === 1 ? <Button cursor='not-allowed'><ArrowBackIcon /></Button> : <Button onClick={(e) => beforePage()}><ArrowBackIcon /></Button>
                                    }

                                    <Text px='10px'>{current_page}</Text>
                                    {
                                        current_page < Math.floor(Number(total_users) / 10) + 1 ? <Button onClick={(e) => nextPage()}><ArrowForwardIcon /></Button> : <Button cursor='not-allowed'><ArrowForwardIcon /></Button>
                                    }
                                </Flex>
                            </Flex></>
                    }

                </Box>
            </Card>
        </Box>
    );
} 
const apiEndpoints: any = {
  get_dashboard_header_data:'https://app.tradingmachine.ai/api/get_dashboard_header_data/',
  get_profits:'https://app.tradingmachine.ai/api/get_profits/',
  get_all_positions_data:'https://app.tradingmachine.ai/api/get_all_positions_data/',
  get_user_balance:'https://app.tradingmachine.ai/api/get_user_balance/',
  balance_history:'https://app.tradingmachine.ai/api/balance_history/',
  get_daily_pnl:'https://app.tradingmachine.ai/api/get_daily_pnl/',
  get_balance_pnl:'https://app.tradingmachine.ai/api/get_balance_pnl/',
  get_best_trading_pairs:'https://app.tradingmachine.ai/api/get_best_trading_pairs/'
}

export default apiEndpoints;
